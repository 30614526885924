<script>
  import { get } from 'vuex-pathify'
  import { events } from '@/helpers/gtm'
  import screen from '@/helpers/screen'
  import EssentialsWideBanner from '@/components/essentials/EssentialsWideBanner.vue'
  import EssentialsCategoryBanner from '@/components/essentials/EssentialsCategoryBanner.vue'
  import ProductSlider from '@/components/product/ProductSlider'

  export default {
    components: {
      EssentialsWideBanner,
      EssentialsCategoryBanner,
      ProductSlider,
    },
    data() {
      return {
        shippingTimelinesModalOpen: false,
        onboardingModalClosed: false,
      }
    },
    computed: {
      ...get('essentials', ['firstTenProducts', 'discoverProducts', 'categories']),
      screen,
      showOnboardingModal() {
        return this.$cookies.isKey('essentialsOnboarding') === false && !this.onboardingModalClosed
      },
      sliderProducts() {
        return this.discoverProducts.length > 3 ? this.discoverProducts : this.firstTenProducts
      },
    },
    created() {
      events.log({
        name: 'viewed essentials discover',
      })
    },
    methods: {
      closeModal() {
        this.$cookies.set('essentialsOnboarding', true, '10y')
        this.onboardingModalClosed = true
        this.$navigate({ name: 'EssentialsHome' })
      },
      viewProduct(product) {
        this.$navigate({
          name: 'EssentialsProductHome',
          params: {
            ...this.$route.params,
            productId: product.id,
            productSlug: product.slug,
            productTitle: product.title,
            type: 'essentials',
          },
        })
      },
    },
  }
</script>

<template>
  <div class="relative flex flex-col">
    <RouterView />
    <!-- Hero banner -->
    <EssentialsWideBanner>
      <template v-slot:title>
        <span class="font-light text-4xl lg:text-6xl xl:text-8xl leading-none">
          HOME &#47; HEALTH &#47; <br />
          AND THE PLANET.
          <div class="absolute mt-1 xl:mt-2 border-b lg:border-2 xl:border-3 border-black w-full" />
        </span>
      </template>
      <template v-slot:subtitle>
        Introducing the Essentials Shop. Members-only prices on sustainable products, shipped within
        two days.
        <BaseLinkStyled @click="shippingTimelinesModalOpen = true">
          <template v-slot:icon>
            <IconInfo />
          </template>
        </BaseLinkStyled>
      </template>
      <template v-slot:image>
        <BaseImage
          src="/static/essentials/EssentialsHero.png"
          alt=""
          responsive="md"
          :sizes="{ default: '100vw', lg: '50vw' }"
        />
      </template>
    </EssentialsWideBanner>

    <!-- 4 category tiles -->
    <div class="p-2 md:px-0 md:py-6 grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-6">
      <EssentialsCategoryBanner
        v-for="({ slug, title }, categoryIndex) in categories"
        :key="categoryIndex"
        :to="{
          name: 'EssentialsPLP',
          params: {
            categorySlugL1: slug,
          },
        }"
      >
        <template v-slot:image>
          <BaseImage
            :src="`/static/essentials/EssentialsHomeCategory${categoryIndex + 1}.png`"
            alt=""
            responsive="sm"
            :sizes="{ default: '50vw', lg: '25vw' }"
          />
        </template>
        <template v-slot:cta>
          <div class="uppercase">{{ title }}</div>
        </template>
      </EssentialsCategoryBanner>
    </div>

    <!-- Banned Materials banner -->
    <EssentialsWideBanner text-on-top color="black" :to="{ name: 'EssentialsBannedMaterials' }">
      <template v-slot:title>
        <div class="font-light text-4xl lg:text-6xl xl:text-8xl leading-none">
          <span class="font-medium">OUR STANDARDS</span> &#47; MUSTS &#47; <s>NEVERS</s> OF OUR SHOP
          <div class="absolute mt-1 xl:mt-2 border-b lg:border-2 xl:border-3 border-dawn w-full" />
        </div>
      </template>
      <template v-slot:subtitle>
        Read more
        <BaseIcon :size="4" class="inline-block align-middle">
          <IconChevronRight />
        </BaseIcon>
      </template>
      <template v-slot:image>
        <BaseImage
          src="/static/essentials/EssentialsBanned.png"
          alt=""
          responsive="md"
          :sizes="{ default: '100vw', lg: '50vw' }"
        />
      </template>
    </EssentialsWideBanner>

    <!-- Product slider -->
    <div class="bg-dawn md:mt-6 overflow-hidden">
      <div class="px-3">
        <h2 class="text-xl underline my-6 ml-2 md:ml-4">THE EDIT</h2>
        <ProductSlider
          :products="sliderProducts"
          :slides-per-view="screen.md ? 4 : 2"
          type="essentials"
          class="px-1"
          @selectProduct="viewProduct($event)"
        />
        <BaseDivider color="black" :weight="3" class="mt-4 md:mt-14 mx-2 md:mx-5" />
      </div>
      <div class="flex items-center justify-center my-6 md:my-16">
        <BaseImage
          v-if="screen.xs"
          src="/static/essentials/banned/EssentialsBannedPageIllustration3.png"
          alt=""
          responsive="xl"
        />
        <BaseImage
          v-else
          src="/static/essentials/EssentialsIllustration.png"
          alt=""
          responsive="sm"
        />
      </div>
    </div>
    <Portal to="modal">
      <BaseModal
        :open="shippingTimelinesModalOpen"
        size="xs"
        center-content
        bg-color="dawn"
        dismissible
        class="text-center"
        @dismiss="shippingTimelinesModalOpen = false"
      >
        <BaseImage
          src="/static/essentials/EssentialsShippingModal.png"
          alt=""
          background-transparent
          responsive="sm"
        />
        <BaseHeading size="h2">Essentials Shop Shipping</BaseHeading>
        <p class="text-xs mt-2">
          All products are fulfilled from our warehouse within two business days of ordering, actual
          arrival time subject to carrier speed.
        </p>
        <BaseLinkStyled
          color="black"
          class="font-medium mt-11 mb-3"
          @click="shippingTimelinesModalOpen = false"
        >
          Got it
        </BaseLinkStyled>
      </BaseModal>
      <BaseModal
        size="sm"
        :open="showOnboardingModal"
        dismissible
        no-vertical-padding
        bg-color="dawn"
        @dismiss="closeModal"
      >
        <div class="flex flex-col px-5 py-8">
          <h1 class="font-heading leading-none text-5xl mt-4">Essentials Shop</h1>
          <h2 class="font-heading font-light leading-none text-xxl uppercase my-2">
            Health and home must-haves &#47; Everyday alternatives shipped within two days.
          </h2>
          <BaseDivider color="black" :weight="screen.md ? 2 : 1" class="mb-4" />
          <img
            src="/static/essentials/EssentialsOnboardingModal.gif"
            alt=""
            class="h-30 md:h-50 w-full object-cover"
          />
          <p class="text-3xs leading-snug mt-4 mb-8">
            We do our homework so you don’t have to. Shop our curated selection of sustainable
            products at Members-only prices, delivered to your home within a week.
          </p>
          <BaseLink class="text-center text-xs" @click="closeModal">
            Start shopping
            <BaseIcon :size="screen.md ? 5 : 3" class="inline-block align-middle"
              ><IconChevronRight
            /></BaseIcon>
          </BaseLink>
        </div>
      </BaseModal>
    </Portal>
  </div>
</template>
